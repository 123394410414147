import './Menu.css'

function Menu() {
    return (
        <header className="menu">
            <p> Experience | Tools | About </p>
        </header>
    )
}

export default Menu