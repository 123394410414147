import './App.css';
import Menu from './components/Menu/Menu';

function App() {
  return (
    <div className="App">
      <Menu></Menu>
      <header className="App-header">
        <p>
          Welcome to ptarasiuk.com
        </p>
        <p>
          Site under construction!
        </p>
      </header>
    </div>
  );
}

export default App;
